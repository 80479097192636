const init = (wrapper) => {
  const video = wrapper.querySelector('.js-inline-video-player-video')
  const posterMobile = video.dataset.posterMobile
  const posterDesktop = video.dataset.posterDesktop
  const srcMobile = video.dataset.srcMobile
  const srcDesktop = video.dataset.srcDesktop

  if (!video.paused) {
    wrapper.classList.add('is-playing')
  } else {
    wrapper.classList.remove('is-playing')
  }

  video.addEventListener('playing', () => {
    wrapper.classList.add('is-playing')
  })

  video.addEventListener('pause', () => {
    wrapper.classList.remove('is-playing')
  })

  video.addEventListener('ended', () => {
    wrapper.classList.remove('is-playing')
  })

  const mql = window.matchMedia('(min-width: 768px)')

  const handleMediaChange = () => {
    if (mql.matches) {
      video.setAttribute('poster', posterDesktop)
      video.setAttribute('src', srcDesktop)
    } else {
      video.setAttribute('poster', posterMobile)
      video.setAttribute('src', srcMobile)
    }

    if (video.hasAttribute('data-autoplay')) {
      video.play()
    }
  }

  mql.addEventListener('change', handleMediaChange)
  handleMediaChange()

  const controlButton = wrapper.querySelector('.js-inline-video-player-control-button')

  const togglePlayPause = () => {
    if (!video.paused) {
      video.pause()
      controlButton.setAttribute('aria-label', 'Play')
    } else {
      video.play()
      controlButton.setAttribute('aria-label', 'Pause')
      wrapper.classList.remove('is-hovered')

      if (wrapper.classList.contains('inline-video-player--clickable')) {
        const overlay = wrapper.querySelector('.js-inline-video-player-overlay')
        overlay.focus()
      }
    }
  }

  controlButton.addEventListener('click', togglePlayPause)

  if (wrapper.classList.contains('inline-video-player--clickable')) {
    const overlay = wrapper.querySelector('.js-inline-video-player-overlay')
    overlay.setAttribute('tabindex', '-1')
    overlay.addEventListener('click', togglePlayPause)
    overlay.addEventListener('keydown', (event) => {
      if (event.keyCode === 32) {
        event.preventDefault()
        togglePlayPause()
      }
    })
  }

  wrapper.addEventListener('mouseenter', () => wrapper.classList.add('is-hovered'))
  wrapper.addEventListener('mouseout', () => wrapper.classList.remove('is-hovered'))
}

export default () => {
  document.querySelectorAll('.js-inline-video-player').forEach((wrapper) => {
    if (!wrapper.classList.contains('is-initialized')) {
      wrapper.classList.add('is-initialized')
      init(wrapper)
    }
  })
}

